import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule, Router } from '@angular/router';
import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { SwiperModule } from 'swiper/angular';

import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatBadgeModule } from '@angular/material/badge';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PlatformModule } from '@angular/cdk/platform';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';

import { AppComponent } from './app.component';
import { CompareResultsComponent } from './pages/compare-results/compare-results.component';
import { HeaderComponent } from './components/header/header.component';
import { RulesComponent } from './pages/rules/rules.component';
import { LandingComponent } from './pages/landing/landing.component';
import { OptionSearchComponent } from './components/option-search/option-search.component';
import { FeaturedPlatesComponent } from './components/featured-plates/featured-plates.component';
import { PlateComponent } from './components/plate/plate.component';
import { AuctionsComponent } from './pages/auctions/auctions.component';
import { CreateAuctionComponent } from './pages/create-auction/create-auction.component';
import { PlateInputComponent } from './components/plate-input/plate-input.component';
import { RouterButtonComponent } from './components/router-button/router-button.component';
import { SearchResultsPageComponent } from './pages/search-results-page/search-results-page.component';
import { SearchResultTableComponent } from './components/search-result-table/search-result-table.component';
import { PlateModalComponent } from './components/plate-modal/plate-modal.component';
import { InfoComponent } from './pages/info/info.component';
import { AccountComponent } from './pages/account/account.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { RegisterComponent } from './pages/register/register.component';
import { CompareResultTableComponent } from './components/compare-result-table/compare-result-table.component';
import { CheckoutCancelComponent } from './pages/checkout-cancel/checkout-cancel.component';
import { OrderConfirmationComponent } from './pages/order-confirmation/order-confirmation.component';
import { GlossaryDefPipe, GlossaryLinkPipe } from './pipes/GlossaryLinkPipe';

import { NoCacheHeadersInterceptor } from './http/no-cache-header';
import { UrlInterceptor } from './http/url-interceptor';
import { TokenInterceptor } from './http/token-interceptor';
import { SessionService } from './services/session-service';
import { UserService } from './services/user-service';
import { BYOSearchPageComponent } from './pages/byo-search-page/byo-search-page.component';
import { ListingCardComponent } from './components/listing-card/listing-card.component';
import { CompareResultRowComponent } from './components/compare-result-row/compare-result-row.component';
import { WildcardCharacterSelectComponent } from './components/wildcard-character-select/wildcard-character-select.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { FeedbackModalComponent } from './components/feedback-modal/feedback-modal.component';
import { FeedbackDialogModalComponent } from './components/feedback-dialog-modal/feedback-dialog-modal.component';
import { SpecialOfferSectionComponent } from './components/special-offer-section/special-offer-section.component';
import { SearchingModalComponent } from './components/searching-modal/searching-modal.component';
import { AdminPageComponent } from './pages/admin-page/admin-page.component';
import { PlateBreakdownComponent } from './components/plate-breakdown/plate-breakdown.component';
import { SearchResultRowComponent } from './components/search-result-row/search-result-row.component';
import { InitialsSearchPageComponent } from './pages/initials-search-page/initials-search-page.component';
import { InitialSectionComponent } from './components/initial-section/initial-section.component';
import { PlatopediaPageComponent } from './pages/platopedia-page/platopedia-page.component';
import { PlatopediaListComponent } from './components/platopedia-list/platopedia-list.component';
import { MultiSearchPageComponent } from './pages/multi-search-page/multi-search-page.component';
import { NameListPageComponent } from './pages/name-list-page/name-list-page.component';
import { FavouritesListComponent } from './components/favourites-list/favourites-list.component';
import { ResultsHeaderComponent } from './components/results-header/results-header.component';
import { SeeMoreButtonComponent } from './components/see-more-button/see-more-button.component';
import { TermListResultRowComponent } from './components/term-list-result-row/term-list-result-row.component';
import { DatelessSearchPageComponent } from './pages/dateless-search-page/dateless-search-page.component';
import { PlateViewerComponent } from './components/plate-viewer/plate-viewer.component';
import { LargeImageModalComponent } from './components/large-image-modal/large-image-modal.component';
import { PlateSellerRowComponent } from './components/plate-seller-row/plate-seller-row.component';
import { PlateInfoPanelComponent } from './components/plate-info-panel/plate-info-panel.component';
import { PlateCheckoutStepsComponent } from './components/plate-checkout-steps/plate-checkout-steps.component';
import { NomineeFormComponent } from './components/nominee-form/nominee-form.component';
import { ChartComponent } from './components/chart/chart.component';
import { PlateOptionSelectionComponent } from './components/plate-option-selection/plate-option-selection.component';
import { NgChartsModule } from 'ng2-charts';
import { CompareInlineComponent } from './components/compare-inline/compare-inline.component';
import { QuickSignUpModalComponent } from './components/quick-sign-up-modal/quick-sign-up-modal.component';
import { RatingBarComponent } from './components/rating-bar/rating-bar.component';
import { SoldDataTableComponent } from './components/sold-data-table/sold-data-table.component';

import * as Sentry from '@sentry/angular';
import { PriceBreakdownComponent } from './components/price-breakdown/price-breakdown.component';
import { SearchVariantBarComponent } from './components/search-variant-bar/search-variant-bar.component';
import { SignedUpContentComponent } from './components/signed-up-content/signed-up-content.component';
import { BannerImageComponent } from './components/banner-image/banner-image.component';
import { SellingLandingPageComponent } from './pages/selling-landing-page/selling-landing-page.component';
import { TabbedSectionComponent } from './components/tabbed-section/tabbed-section.component';
import { SellersHubPageComponent } from './pages/sellers-hub-page/sellers-hub-page.component';
import { BuyPageComponent } from './pages/buy-page/buy-page.component';
import { MessengerPageComponent } from './pages/messenger-page/messenger-page.component';
import { MessengerListComponent } from './components/messenger-list/messenger-list.component';
import { MessengerClientComponent } from './components/messenger-client/messenger-client.component';
import { SellerTableComponent } from './componts/seller-table/seller-table.component';
import { PlatexDirectComponent } from './components/platex-direct/platex-direct.component';
import { ComparePageComponent } from './pages/compare-page/compare-page.component';
import { CompareResultsTableComponent } from './components/compare-results-table/compare-results-table.component';
import { RegistrationDetailPageComponent } from './pages/registration-detail-page/registration-detail-page.component';
import { ImageSliderComponent } from './components/image-slider/image-slider.component';
import { PaymentOptionsComponent } from './components/payment-options/payment-options.component';
import { PaypalComponent } from './components/payment/paypal/paypal.component';
import { PageStepsComponent } from './components/page-steps/page-steps.component';
import { HomePageSearchComponent } from './components/home-page-search/home-page-search.component';
import { ByoSearchInputComponent } from './byo-search-input/byo-search-input.component';
import { DvlaAuctionPageComponent } from './pages/dvla-auction-page/dvla-auction-page.component';
import { AccountDetailsComponent } from './pages/account-details/account-details.component';
import { AccountFavouritesComponent } from './pages/account-favourites/account-favourites.component';
import { AccountNotifyComponent } from './pages/account-notify/account-notify.component';
import { AccountAlertsComponent } from './pages/account-alerts/account-alerts.component';
import { AccountMessagesComponent } from './pages/account-messages/account-messages.component';
import { AccountSellingComponent } from './pages/account-selling/account-selling.component';
import { GlossaryComponent } from './components/glossary/glossary.component';
import { GlossaryTermWordComponent } from './glossary-term-word/glossary-term-word.component';
import { SwitchToggleComponent } from './components/switch-toggle/switch-toggle.component';
import { NamePlatePageComponent } from './pages/name-plate-page/name-plate-page.component';
import { SignupCaptureModalComponent } from './components/signup-capture-modal/signup-capture-modal.component';
import { FaqComponent } from './components/faq/faq.component';
import { EditListingPageComponent } from './pages/edit-listing-page/edit-listing-page.component';
import { BillingListingPageComponent } from './pages/billing-listing-page/billing-listing-page.component';
import { TrackingChartComponent } from './components/tracking-chart/tracking-chart.component';
import { PlateViewerPageComponent } from './pages/plate-viewer-page/plate-viewer-page.component';
import { BlogPageComponent } from './pages/blog-page/blog-page.component';
import { BlogLandingPageComponent } from './pages/blog-landing-page/blog-landing-page.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { OrdinalDatePipe } from './pipes/OrdinalDatePipe';
import { ImageCachePipe } from './pipes/ImageCachePipe';
import { OrdinalNumberPipe } from './pipes/OrdinalNumberPipe';
import { TpSellersComponent } from './components/tp-sellers/tp-sellers.component';
import { SaleDataComponent } from './components/sale-data/sale-data.component';
import { StickyTabsComponent } from './components/sticky-tabs/sticky-tabs.component';
import { PriceHistoryComponent } from './components/price-history/price-history.component';
import { CompareLoaderComponent } from './components/compare-loader/compare-loader.component';
import { NgxMarqueeModule } from 'ngx-marquee';
import { SideBarFiltersComponent } from './components/side-bar-filters/side-bar-filters.component';
import { CustomDropdownComponent } from './components/custom-dropdown/custom-dropdown.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MarketInsightComponent } from './components/market-insight/market-insight.component';
import { DynamicChartsComponent } from './components/dynamic-charts/dynamic-charts.component';
import { UserHistoryComponent } from './components/user-history/user-history.component';
import { ChartPriceDataComponent } from './chart-price-data/chart-price-data.component';
import { NumberPlateTypesComponent } from './pages/articles/number-plate-types/number-plate-types.component';
import { PrivateNumberPlatesComponent } from './pages/articles/private-number-plates/private-number-plates.component';
import { CherishedNumberPlatesComponent } from './pages/articles/cherished-number-plates/cherished-number-plates.component';
import { DatelessNumberPlatesComponent } from './pages/articles/dateless-number-plates/dateless-number-plates.component';
import { PrefixNumberPlatesComponent } from './pages/articles/prefix-number-plates/prefix-number-plates.component';
import { SuffixNumberPlatesComponent } from './pages/articles/suffix-number-plates/suffix-number-plates.component';
import { HowToTransferARegistrationComponent } from './pages/articles/how-to-transfer-a-registration/how-to-transfer-a-registration.component';
import { HowToRetainARegistrationComponent } from './pages/articles/how-to-retain-a-registration/how-to-retain-a-registration.component';
import { HowToPurchaseARegistrationComponent } from './pages/articles/how-to-purchase-a-registration/how-to-purchase-a-registration.component';
import { FeaturedBlogsComponent } from './featured-blogs/featured-blogs.component';
import { ArticleLandingComponent } from './article-landing/article-landing.component';
import { ChartPriceHistoryComponent } from './chart-price-history/chart-price-history.component';
import { NewReleasePageComponent } from './pages/new-release-page/new-release-page.component';
import { PagePlacementComponent } from './components/page-placement/page-placement.component';
import { NewReleaseMarketDataComponent } from './components/new-release-market-data/new-release-market-data.component';
import { NamePopularityDataComponent } from './components/name-popularity-data/name-popularity-data.component';
import { AdminGptComponent } from './pages/admin-gpt/admin-gpt.component';
import { AdminWordbankComponent } from './pages/admin-wordbank/admin-wordbank.component';
import { BuyLandingComponent } from './pages/buy-landing/buy-landing.component';
import { SellLandingComponent } from './pages/sell-landing/sell-landing.component';
import { FeaturedListingsComponent } from './featured-listings/featured-listings.component';
import { InViewportModule } from 'ng-in-viewport';
import { PriceHistoryChartComponent } from './price-history-chart/price-history-chart.component';
import { InlineFavouriteComponent } from './components/inline-favourite/inline-favourite.component';
import { InlinePlateViewerLinkComponent } from './components/inline-plate-viewer-link/inline-plate-viewer-link.component';
import { InlinePlateRankComponent } from './components/inline-plate-rank/inline-plate-rank.component';
import { CustomLoaderComponent } from './components/custom-loader/custom-loader.component';
import { TextChangerComponent } from './components/text-changer/text-changer.component';
import { HowToSaveMoneyComponent } from './pages/articles/how-to-save-money/how-to-save-money.component';
import { SearchLoaderComponent } from './components/search-loader/search-loader.component';
import { NewSearchInputComponent } from './components/new-search-input/new-search-input.component';
import { PlateDetailPageComponent } from './pages/plate-detail-page/plate-detail-page.component';
import { MainNavChipsComponent } from './components/main-nav-chips/main-nav-chips.component';
import { FooterPopoutComponent } from './components/footer-popout/footer-popout.component';
import { InitialsPopularityDataComponent } from './components/initials-popularity-data/initials-popularity-data.component';
import { InitialsPopularityTableComponent } from './components/initials-popularity-table/initials-popularity-table.component';
import { MarketShareComponent } from './components/market-share/market-share.component';
import { CodeInputModule } from 'angular-code-input';
import { UnderOneHundredComponent } from './pages/under-one-hundred/under-one-hundred.component';
import { NumberOnePageComponent } from './pages/number-one-page/number-one-page.component';
import { SafeHtmlPipe } from './pipes/SafeHtmlPipe';
import { SnowComponent } from './thirdpartycomponents/snow.component';
import { AdminBlogCreateComponent } from './pages/admin-blog-create/admin-blog-create.component';
import { AdminBlogConfigComponent } from './pages/admin-blog-config/admin-blog-config.component';
import { AdminStaticMediaComponent } from './pages/admin-static-media/admin-static-media.component';
import { CustomLoaderBoxComponent } from './components/custom-loader-box/custom-loader-box.component';
import { AdminPlateViewerToggleComponent } from './components/admin-plate-viewer-toggle/admin-plate-viewer-toggle.component';
import { DealerPortalComponent } from './pages/dealer-portal/dealer-portal.component';
import { RegistrationMetricsComponent } from './components/registration-metrics/registration-metrics.component';
import { RelativeTimePipe } from './pipes/RelativeTimePipe';
import { MetricPortalComponent } from './pages/metric-portal/metric-portal.component';

const routes: Routes = [
  // MAIN
  {
    path: '', component: LandingComponent, data: {
      description: 'The UKs Number Plate Portal - Find your perfect private number plate from 40 dealers and the DVLA! Plus FREE price comparison on 73 million personalised plates.',
      image: 'https://cdn.platex.co.uk/banners/PLATEX_R8.jpg'
    }
  },
  {
    path: 'landing', component: LandingComponent, data: {
      description: 'The UKs Number Plate Portal - Find your perfect private number plate from 40 dealers and the DVLA! Plus FREE price comparison on 73 million personalised plates.',
      image: 'https://cdn.platex.co.uk/banners/PLATEX_R8.jpg'
    }
  },
  {
    path: 'landing/:type', component: LandingComponent, data: {
      description: 'The UKs Number Plate Portal - Find your perfect private number plate from 40 dealers and the DVLA! Plus FREE price comparison on 73 million personalised plates.'
    }
  },
  {
    path: 'buy', component: LandingComponent, data: {
      description: 'The UKs Number Plate Portal - Find your perfect private number plate from 40 dealers and the DVLA! Plus FREE price comparison on 73 million personalised plates.',
      image: 'https://cdn.platex.co.uk/banners/PLATEX_R8.jpg'
    }
  },
  { path: 'sell', component: LandingComponent },

  { path: '_buy', component: BuyPageComponent },
  { path: '_sell', component: SellingLandingPageComponent },


  // { path: 'auctions', component: AuctionsComponent },
  { path: 'message-hub', component: MessengerPageComponent },
  { path: 'info', component: InfoComponent },
  {
    path: 'plate-viewer',
    component: PlateViewerPageComponent,
    data: {
      title: 'Plate Viewer - see plate on car',
      description: 'View Number Plates on Car - Photorealistic number plate viewer.  See any number plate on a huge choice of cars from Fiat to Ferrari then download your image and share!',
      image: 'https://cdn.platex.co.uk/banners/PLATEX_R8.jpg'
    },
  },
  {
    path: 'plate-viewer/:type',
    component: PlateViewerPageComponent,
    data: {
      title: 'Plate Viewer - see plate on car',
      description: 'View Number Plates on Car - Photorealistic number plate viewer.  See any number plate on a huge choice of cars from Fiat to Ferrari then download your image and share!',
      image: 'https://cdn.platex.co.uk/banners/PLATEX_R8.jpg'
    },
  },
  {
    path: 'plate-viewer/:type/:input',
    component: PlateViewerPageComponent,
    data: {
      title: 'Plate Viewer - see plate on car',
      description: 'View Number Plates on Car - Photorealistic number plate viewer.  See any number plate on a huge choice of cars from Fiat to Ferrari then download your image and share!',
      image: 'https://cdn.platex.co.uk/banners/PLATEX_R8.jpg'
    },
  },
  {
    path: 'plate-viewer/:type/:input/:plate',
    component: PlateViewerPageComponent,
    data: {
      title: 'Plate Viewer - see plate on car',
      description: 'View Number Plates on Car - Photorealistic number plate viewer.  See any number plate on a huge choice of cars from Fiat to Ferrari then download your image and share!',
      image: 'https://cdn.platex.co.uk/banners/PLATEX_R8.jpg'
    },
  },
  { path: 'info/glossary', component: InfoComponent },
  { path: 'info/glossary/:term', component: InfoComponent },
  {
    path: 'compare', component: ComparePageComponent,
    data: {
      title: 'PlateX - Price Compare',
      description: 'FREE and instant price comparison across 40+ UK private number plate dealers. Find the best deal on over 73 million personal plates with the UK’s private plate portal.',
      image: 'https://cdn.platex.co.uk/banners/HATE_TAX_DIABLO_DT.png'
    },
  },
  {
    path: 'compare/:registration',
    component: ComparePageComponent,
    data: {
      title: 'PlateX - {registration} Compare Results',
      description: 'FREE and instant price comparison across 40+ UK private number plate dealers. Find the best deal on over 73 million personal plates with the UK’s private plate portal.',
      image: 'https://cdn.platex.co.uk/banners/HATE_TAX_DIABLO_DT.png'
    },
  },
  { path: 'create-auction', component: CreateAuctionComponent },
  {
    path: 'byo-search', component: BYOSearchPageComponent,
    data: {
      title: 'PlateX - Build Your Own',
      description: 'Create your own private number plate easily using our Make Your Own personal number plate tool and choose from 73 million registrations.',
      image: 'https://cdn.platex.co.uk/large/DE51GNS_RANGEROVER_DEFENDER.png'
    },
  },
  {
    path: 'byo-search/:style',
    component: BYOSearchPageComponent,
    data: {
      title: 'PlateX - Build Your Own',
      description: 'Create your own private number plate easily using our Make Your Own personal number plate tool and choose from 73 million registrations.',
      image: 'https://cdn.platex.co.uk/large/DE51GNS_RANGEROVER_DEFENDER.png'
    },
  },
  {
    path: 'byo-search/:style/:prefix/:numbers/:letters',
    component: BYOSearchPageComponent,
    data: {
      title: 'PlateX - Build Your Own',
      description: 'Create your own private number plate easily using our Make Your Own personal number plate tool and choose from 73 million registrations.',
      image: 'https://cdn.platex.co.uk/large/DE51GNS_RANGEROVER_DEFENDER.png'
    },
  },
  {
    path: 'plate-detail/:registration',
    component: PlateDetailPageComponent,
    data: {
      title: 'PlateX - {registration} Detail',
    },
  },
  {
    path: 'plate-detail/:registration/:term',
    component: PlateDetailPageComponent,
    data: {
      title: 'PlateX - {registration} Detail',
    },
  },
  {
    path: 'search-results/:searchCriteria',
    component: SearchResultsPageComponent,
    data: {
      title: 'PlateX - Compare and save on {searchCriteria} number plates',
      description:
        'Find all {searchCriteria} private number plates available from 40+ dealers and the DVLA! Free price comparison on over 73 million personalised plates to save you £££.',
    },
  },
  // initials
  {
    path: 'initials-search',
    component: InitialsSearchPageComponent,
    data: {
      title: 'PlateX - Initials Search',
      description: 'Find your ultimate initials private number plate from the best available from over 40 dealers and DVLA and free price comparison to find you the best deal!',
      image: 'https://cdn.platex.co.uk/large/L5_LAMBORGHINI_DIABLO.png'
    },
  },
  {
    path: 'initials-search/:initials',
    component: InitialsSearchPageComponent,
    data: {
      title: 'PlateX - {initials} Initials Results',
      description: 'Find your ultimate initials private number plate from the best available from over 40 dealers and DVLA and free price comparison to find you the best deal!',
      image: 'https://cdn.platex.co.uk/large/L5_LAMBORGHINI_DIABLO.png'
    },
  },
  // New Release
  {
    path: 'new-release/:releaseYear',
    component: NewReleasePageComponent,
    data: {
      title: 'PlateX - {releaseYear} Release',
      description: 'Find the best words and names from the 6 million new style registrations released with our powerful search tool.  Search private plates from 40+ dealers and the DVLA, then compare prices free to get the best deal!',
      image: 'https://cdn.platex.co.uk/pages/new-release/BERSERK_AMG.png'
    },
  },
  // Names
  {
    path: 'names',
    component: NamePlatePageComponent,
    data: {
      title: 'PlateX - Name Plates',
      description:
        "A collection of the best name private plates from over 40 dealers from across the market.  Names lists from over 28 countries and counting + price comparison helps find you the best deal!",
      iamge: 'https://cdn.platex.co.uk/pages/name-search/JAMES_RR.png'
    },
  },
  // categories
  {
    path: 'categories/:tag',
    component: MultiSearchPageComponent,
  },
  {
    path: 'categories/:tag/:searchCriteria',
    component: MultiSearchPageComponent,
  },
  // sub £200
  {
    path: 'under-200',
    component: UnderOneHundredComponent,
    data: {
      title: 'PlateX - Under 200',
      description: "Cheap Private Plates. We've gathered all private number plates under £200 from across the market in one place only on the UKs private number plate portal.",
      image: 'https://cdn.platex.co.uk/banners/SUB_200_FIAT_500_DT.png'
    }
  },
  // number 1
  {
    path: 'number-1',
    component: NumberOnePageComponent,
    data: {
      title: 'PlateX - Number 1 Plates',
      description: 'All the Number 1 registrations from 40+ dealers and DVLA all in one place with FREE price comparison to help find you the best deal - all at the UKs private number plate portal.',
      image: 'https://cdn.platex.co.uk/banners/NUM_1_DBS_DT.png'
    }
  },
  // dateless by size
  {
    path: 'dateless-search',
    component: DatelessSearchPageComponent,
    data: {
      title: 'PlateX - Dateless Search',
      description: 'The largest collection of dateless private plates from over 40 registration dealers and the DVLA.  Search by letters or numbers then use our FREE price comparison and save £££!',
      image: 'https://cdn.platex.co.uk/large/8UYS_FERRARI_275.png'
    },
  },
  {
    path: 'dateless-search/:length',
    component: DatelessSearchPageComponent,
    data: {
      title: 'PlateX - Dateless Search',
      description: 'The largest collection of dateless private plates from over 40 registration dealers and the DVLA.  Search by letters or numbers then use our FREE price comparison and save £££!',
      image: 'https://cdn.platex.co.uk/large/8UYS_FERRARI_275.png'
    },
  },
  {
    path: 'dateless-search/:length/:searchCriteria',
    component: DatelessSearchPageComponent,
    data: {
      title: 'PlateX - {searchCriteria} Dateless Results',
      description: 'The largest collection of dateless private plates from over 40 registration dealers and the DVLA.  Search by letters or numbers then use our FREE price comparison and save £££!',
      image: 'https://cdn.platex.co.uk/large/8UYS_FERRARI_275.png'
    },
  },
  {
    path: 'dateless-search/:length/:searchCriteria/:startingWith',
    component: DatelessSearchPageComponent,
    data: {
      title: 'PlateX - {searchCriteria} Dateless Results',
      description: 'The largest collection of dateless private plates from over 40 registration dealers and the DVLA.  Search by letters or numbers then use our FREE price comparison and save £££!',
      image: 'https://cdn.platex.co.uk/large/8UYS_FERRARI_275.png'
    },
  },

  // dealer
  {
    path: 'dealer/home',
    component: DealerPortalComponent,
    data: {
      title: 'PlateX - Dealer Portal',
    },
  },

  // dvla auction
  // {
  //   path: 'dvla-auction',
  //   component: DvlaAuctionPageComponent,
  // },
  // {
  //   path: 'dvla-auction/:searchCriteria',
  //   component: DvlaAuctionPageComponent,
  // },
  // account
  { path: 'login', component: LoginPageComponent },
  { path: 'register', component: RegisterComponent },
  // {
  //   path: 'order-confirmation/:orderId',
  //   component: OrderConfirmationComponent,
  // },
  // { path: 'checkout-cancel', component: CheckoutCancelComponent },
  // ACCOUNT
  { path: 'account', component: AccountComponent },
  { path: 'account/:pageSection', component: AccountComponent },
  { path: 'sellers-hub', component: SellersHubPageComponent },

  // ADVERT
  { path: 'advert-edit/:advertId', component: EditListingPageComponent },
  { path: 'advert-billing/:advertId', component: BillingListingPageComponent },

  // BLOG
  {
    path: 'blogs', component: BlogLandingPageComponent, data: {
      title: 'PlateX - Blogs'
    }
  },
  {
    path: 'blogs/:blogHandle', component: BlogPageComponent, data: {
      title: 'PlateX - Blogs'
    }
  },

  {
    path: 'articles',
    component: ArticleLandingComponent,
    data: {
      title: 'PlateX - Resources',
    },
  },
  {
    path: 'articles/number-plate-types',
    component: NumberPlateTypesComponent,
    data: {
      title: 'PlateX - Number Plate Types',
    },
  },
  {
    path: 'articles/private-number-plates',
    component: PrivateNumberPlatesComponent,
    data: {
      title: 'PlateX - Private Number Plates',
    },
  },
  {
    path: 'articles/cherished-number-plates',
    component: CherishedNumberPlatesComponent,
    data: {
      title: 'PlateX - Cherished Number Plates',
    },
  },
  {
    path: 'articles/dateless-number-plates',
    component: DatelessNumberPlatesComponent,
    data: {
      title: 'PlateX - Dateless Number Plates',
    },
  },
  {
    path: 'articles/prefix-number-plates',
    component: PrefixNumberPlatesComponent,
    data: {
      title: 'PlateX - Prefix Number Plates',
    },
  },
  {
    path: 'articles/suffix-number-plates',
    component: SuffixNumberPlatesComponent,
    data: {
      title: 'PlateX - Suffix Number Plates',
    },
  },
  {
    path: 'articles/how-to-transfer-a-registration',
    component: HowToTransferARegistrationComponent,
    data: {
      title: 'PlateX - How To Transfer A Registration',
    },
  },
  {
    path: 'articles/how-to-retain-a-registration',
    component: HowToRetainARegistrationComponent,
    data: {
      title: 'PlateX - How To Retain A Registration',
    },
  },
  {
    path: 'articles/how-to-purchase-a-registration',
    component: HowToPurchaseARegistrationComponent,
    data: {
      title: 'PlateX - How To Purchase A Registration',
    },
  },
  {
    path: 'articles/how-to-save-money',
    component: HowToSaveMoneyComponent,
    data: {
      title: 'PlateX - How To Save Money',
    },
  },

  // ERROR HANDLING
  { path: '**', component: LandingComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    CompareResultsComponent,
    HeaderComponent,
    RulesComponent,
    LandingComponent,
    OptionSearchComponent,
    FeaturedPlatesComponent,
    PlateComponent,
    AuctionsComponent,
    CreateAuctionComponent,
    PlateInputComponent,
    RouterButtonComponent,
    SearchResultsPageComponent,
    SearchResultTableComponent,
    PlateModalComponent,
    InfoComponent,
    AccountComponent,
    FooterComponent,
    LoginPageComponent,
    RegisterComponent,
    CompareResultTableComponent,
    CheckoutCancelComponent,
    OrderConfirmationComponent,
    BYOSearchPageComponent,
    ListingCardComponent,
    CompareResultRowComponent,
    WildcardCharacterSelectComponent,
    TopBarComponent,
    FeedbackModalComponent,
    FeedbackDialogModalComponent,
    SpecialOfferSectionComponent,
    SearchingModalComponent,
    AdminPageComponent,
    PlateBreakdownComponent,
    SearchResultRowComponent,
    InitialsSearchPageComponent,
    InitialSectionComponent,
    PlatopediaPageComponent,
    PlatopediaListComponent,
    MultiSearchPageComponent,
    NameListPageComponent,
    FavouritesListComponent,
    ResultsHeaderComponent,
    SeeMoreButtonComponent,
    TermListResultRowComponent,
    DatelessSearchPageComponent,
    PlateViewerComponent,
    LargeImageModalComponent,
    PlateSellerRowComponent,
    PlateInfoPanelComponent,
    PlateCheckoutStepsComponent,
    NomineeFormComponent,
    ChartComponent,
    PlateOptionSelectionComponent,
    CompareInlineComponent,
    QuickSignUpModalComponent,
    RatingBarComponent,
    SoldDataTableComponent,
    PriceBreakdownComponent,
    SearchVariantBarComponent,
    SignedUpContentComponent,
    BannerImageComponent,
    SellingLandingPageComponent,
    TabbedSectionComponent,
    SellersHubPageComponent,
    BuyPageComponent,
    MessengerPageComponent,
    MessengerListComponent,
    MessengerClientComponent,
    SellerTableComponent,
    PlatexDirectComponent,
    ComparePageComponent,
    CompareResultsTableComponent,
    RegistrationDetailPageComponent,
    PlateDetailPageComponent,
    ImageSliderComponent,
    PaymentOptionsComponent,
    PaypalComponent,
    PageStepsComponent,
    HomePageSearchComponent,
    ByoSearchInputComponent,
    DvlaAuctionPageComponent,
    AccountDetailsComponent,
    AccountFavouritesComponent,
    AccountNotifyComponent,
    AccountAlertsComponent,
    AccountMessagesComponent,
    AccountSellingComponent,
    GlossaryComponent,
    GlossaryLinkPipe,
    OrdinalDatePipe,
    OrdinalNumberPipe,
    ImageCachePipe,
    RelativeTimePipe,
    GlossaryDefPipe,
    SafeHtmlPipe,
    GlossaryTermWordComponent,
    SwitchToggleComponent,
    NamePlatePageComponent,
    SignupCaptureModalComponent,
    FaqComponent,
    EditListingPageComponent,
    BillingListingPageComponent,
    TrackingChartComponent,
    PlateViewerPageComponent,
    BlogPageComponent,
    BlogLandingPageComponent,
    SideNavComponent,
    TpSellersComponent,
    SaleDataComponent,
    StickyTabsComponent,
    PriceHistoryComponent,
    CompareLoaderComponent,
    SearchLoaderComponent,
    SideBarFiltersComponent,
    CustomDropdownComponent,
    LoaderComponent,
    MarketInsightComponent,
    DynamicChartsComponent,
    UserHistoryComponent,
    ChartPriceDataComponent,
    NumberPlateTypesComponent,
    PrivateNumberPlatesComponent,
    CherishedNumberPlatesComponent,
    DatelessNumberPlatesComponent,
    PrefixNumberPlatesComponent,
    SuffixNumberPlatesComponent,
    HowToTransferARegistrationComponent,
    HowToRetainARegistrationComponent,
    HowToPurchaseARegistrationComponent,
    HowToSaveMoneyComponent,
    FeaturedBlogsComponent,
    FeaturedListingsComponent,
    ArticleLandingComponent,
    ChartPriceHistoryComponent,
    NewReleasePageComponent,
    PagePlacementComponent,
    NewReleaseMarketDataComponent,
    NamePopularityDataComponent,
    AdminGptComponent,
    AdminWordbankComponent,
    BuyLandingComponent,
    SellLandingComponent,
    PriceHistoryChartComponent,
    InlineFavouriteComponent,
    InlinePlateViewerLinkComponent,
    InlinePlateRankComponent,
    CustomLoaderComponent,
    TextChangerComponent,
    NewSearchInputComponent,
    PlateDetailPageComponent,
    MainNavChipsComponent,
    FooterPopoutComponent,
    InitialsPopularityDataComponent,
    InitialsPopularityTableComponent,
    MarketShareComponent,
    UnderOneHundredComponent,
    NumberOnePageComponent,
    SnowComponent,
    AdminBlogCreateComponent,
    AdminBlogConfigComponent,
    AdminStaticMediaComponent,
    CustomLoaderBoxComponent,
    AdminPlateViewerToggleComponent,
    DealerPortalComponent,
    RegistrationMetricsComponent,
    MetricPortalComponent
  ],
  imports: [
    NgChartsModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      onSameUrlNavigation: 'reload',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 0]
    }),
    CodeInputModule.forRoot({}),
    InViewportModule,
    HttpClientModule,
    FormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatMenuModule,
    MatIconModule,
    MatSelectModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    MatSliderModule,
    MatCheckboxModule,
    MatDialogModule,
    MatCardModule,
    MatTooltipModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatTableModule,
    MatBadgeModule,
    MatExpansionModule,
    MatDividerModule,
    MatTabsModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    PlatformModule,
    MatRippleModule,
    SwiperModule,
    MatStepperModule,
    NgChartsModule,
    MatAutocompleteModule,
    MatRadioModule,
    NgxMarqueeModule,
  ],
  exports: [RouterModule],
  providers: [
    SessionService,
    UserService,
    MatDialogConfig,
    CurrencyPipe,
    DatePipe,
    GlossaryLinkPipe,
    GlossaryDefPipe,
    SafeHtmlPipe,
    DecimalPipe,
    ImageCachePipe,
    RelativeTimePipe,
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: false,
    //   }),
    // },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UrlInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheHeadersInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
