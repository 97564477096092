import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HTTPCONSTANTS } from '../http/http-constants';
import { PlateViewerConfig } from '../models/plateViewerConfig';
import { PlatexComparisonSite } from '../models/platexComparisonSite';
import { SplitSearchResp } from '../models/splitSearchResp';
import { PlatopediaWord } from '../models/platopediaWord';
import { RawSoldPlate } from '../models/registration';
import { AdminWordbankWord } from '../pages/admin-wordbank/admin-wordbank.component';
import { BlogCreateRequest } from '../models/blogCreateRequest';

@Injectable({ providedIn: 'root' })
export class AdminService {
  constructor(private http: HttpClient) { }

  public verify(): Observable<any> {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/authenticate/verify`;
    return this.http.get(url);
  }

  public getMetricsForPeriod(trackingType: number, from: Date, to: Date, callback: (res: { registration: string, track_type: number, result_count: number }[]) => void): void {
    this.http
      .get<{ registration: string, track_type: number, result_count: number }[]>(
        `${HTTPCONSTANTS.plato_search_protocol}${HTTPCONSTANTS.plato_search_base}?process=admin-metrics&tracking_type=${trackingType}&date_from=${from.toISOString().substr(0, 10)}&date_to=${to.toISOString().substr(0, 10)}`
      )
      .subscribe(
        (_: { registration: string, track_type: number, result_count: number }[]) => {
          callback(_);
        },
        () => {
          callback(null);
        }
      );
  }

  // Get the list of images
  getImages(): Observable<string[]> {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/admin/get-images`;
    return this.http.get<string[]>(url);
  }

  // Upload a static image
  uploadStaticImage(file: File): Observable<{ url: string }> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const headers = new HttpHeaders();
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/admin/add-static-media/${file.name}`;
    return this.http.post<{ url: string }>(url, formData, { headers });
  }

  public UpdateBlog(id: number, blogRequest: BlogCreateRequest, callback: (status: boolean) => void): void {
    this.http.put(`${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/blog/update-blog/${id}`, blogRequest).subscribe(
      (response) => {
        console.log('Blog updated successfully:', response);
        callback(true);
      },
      (error) => {
        console.error('Error creating blog:', error);
        callback(false);
      }
    );
  }

  public CreateBlog(blogRequest: BlogCreateRequest, callback: (status: boolean) => void): void {
    this.http.post(`${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/blog/create-blog`, blogRequest).subscribe(
      (response) => {
        console.log('Blog created successfully:', response);
        callback(true);
      },
      (error) => {
        console.error('Error creating blog:', error);
        callback(false);
      }
    );
  }

  public getWordbank(filter: string, callback: (res: AdminWordbankWord[]) => void): void {
    this.http
      .get<AdminWordbankWord[]>(
        `${HTTPCONSTANTS.plato_search_protocol}${HTTPCONSTANTS.plato_search_base}?process=admin-term-list&filters=${filter}`
      )
      .subscribe(
        (_: AdminWordbankWord[]) => {
          callback(_);
        },
        () => {
          callback(null);
        }
      );
  }

  public DeleteWordbank(term: number, callback: () => void): void {
    this.http
      .get(
        `${HTTPCONSTANTS.plato_search_protocol}${HTTPCONSTANTS.plato_search_base}?process=admin-delete-term&term=${term}`
      )
      .subscribe(
        () => {
          callback();
        },
        () => {
          callback();
        }
      );
  }

  public searchAI(
    search: string,
    callback: (res: { request: string, query: string, res: RawSoldPlate[] }) => void
  ): void {
    this.http
      .get<{ request: string, query: string, res: RawSoldPlate[] }>(
        `${HTTPCONSTANTS.plato_search_protocol}${HTTPCONSTANTS.plato_search_base}?process=data-ai-helper&term=${search}`
      )
      .subscribe(
        (_: { request: string, query: string, res: RawSoldPlate[] }) => {
          callback(_);
        },
        () => {
          callback(null);
        }
      );
  }

  public splitSearch(
    term: string,
    plate1: string,
    plate2: string
  ): Observable<SplitSearchResp> {
    var url = `${HTTPCONSTANTS.platoProtocol}${HTTPCONSTANTS.platoBaseAddress}/side-compare/${term}/${plate1}/${plate2}`;
    return this.http.get<SplitSearchResp>(url);
  }

  public getAdminImageConfigGroups(): Observable<PlateViewerConfig[]> {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/admin/admin-get-plate-viewer-config-groups`;
    return this.http.get<PlateViewerConfig[]>(url);
  }

  public getImageConfigGroups(): Observable<PlateViewerConfig[]> {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/admin/get-plate-viewer-config-groups`;
    return this.http.get<PlateViewerConfig[]>(url);
  }

  public getImageConfigsForGroup(
    groupId: string
  ): Observable<PlateViewerConfig[]> {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/admin/get-plate-viewer-config-by-group/${groupId}`;
    return this.http.get<PlateViewerConfig[]>(url);
  }

  public getCompareSites(): Observable<PlatexComparisonSite[]> {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/compare/sites`;
    return this.http.get<PlatexComparisonSite[]>(url);
  }

  public addPlatopediaWord(word: PlatopediaWord): Observable<void> {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/platopedia/add`;
    return this.http.post<void>(url, word);
  }

  public addMultiTermHeader(header: string): Observable<void> {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/search/multi-term/header/add/${header}`;
    return this.http.get<void>(url);
  }

  public addMultiTermsToHeader(
    header: string,
    terms: string[]
  ): Observable<void> {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/search/multi-term/terms/add/${header}`;
    return this.http.post<void>(url, terms);
  }
}
